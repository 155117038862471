import type {
  LiteTranslatorService,
  TranslatorService
} from "@app/services/translator";

export function getPassengerCountTranslation(
  translator: LiteTranslatorService | TranslatorService,
  count: number
) {
  if (count === 1) {
    return translator.t("!search.passengers.count", { count: count });
  } else {
    return translator.t("!search.passengers.count-multiple", { count: count });
  }
}

export function getPassengersTranslationAttr(passenger_count: number) {
  return passenger_count === 1
    ? "data-single-translation"
    : "data-multiple-translation";
}
