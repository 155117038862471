import type { Ref } from "react";
import React, { useContext, useRef } from "react";

import { useSelector, useStore } from "react-redux";

import { Alert, Button, Divider } from "@busbud/horizon";

import {
  closeModal,
  useIsPassengersModalOpen
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import { LiteAppContext } from "@app/context/lite-app-context";
import { VALIDATE_FORM_MESSAGE } from "@app/modules/search/constants";
import {
  areChildrenAccompanied,
  getTotalPassengersCount
} from "@app/modules/search/store/selectors/passengers";
import type { LandingPageReduxState } from "@app/types/landing-page";
import type { ResultsReduxState } from "@app/types/results-redux-types";
import { yieldToMain } from "@app/utils/scheduler-yield";

import { getPassengerCountTranslation } from "../../modules/search/helpers/passenger-input";
import { setPassengers } from "../../modules/search/store/slices/search-form/passengers";
import { useAction } from "../../store/hooks/useAction";
import { AdaptiveSelectorContainer } from "../adaptive-selector-container";
import { PassengerAge } from "../search-form/passenger-info-input/passenger-age";
import { PassengerAttributesStudent } from "../search-form/passenger-info-input/passenger-attributes-student";
import { PassengerAttributesWheelchair } from "../search-form/passenger-info-input/passenger-attributes-wheelchair";
import { PassengerCounterInput } from "../search-form/passenger-info-input/passenger-counter-input";

interface Props {
  input_element?: Ref<HTMLInputElement>;
}

const VanillaTranslatedStrings: React.FC<{}> = () => {
  const { liteTranslator } = useContext(LiteAppContext);
  const total_passengers_count = useSelector(getTotalPassengersCount);

  const passengers_count_translation = getPassengerCountTranslation(
    liteTranslator,
    total_passengers_count
  );

  return (
    <input
      id="total-passengers-input"
      type="hidden"
      value={passengers_count_translation}
      data-single-translation={`${liteTranslator.t(
        "!search.passengers.count"
      )}`}
      data-multiple-translation={`${liteTranslator.t(
        "!search.passengers.count-multiple",
        { count: "[num-placeholder]" }
      )}`}
    />
  );
};

const TooManyPassengersAlert: React.FC<{}> = () => {
  const { liteTranslator } = useContext(LiteAppContext);
  const total_passengers_count = useSelector(getTotalPassengersCount);
  const too_many_passengers = total_passengers_count >= 5;

  if (!too_many_passengers) {
    return null;
  }

  return (
    <Alert severity="informative">
      <Alert.Title as="span">
        {liteTranslator.t("!search.passengers.error.title")}
      </Alert.Title>
      <Alert.Description>
        {liteTranslator.t("!search.passengers.error.text")}
      </Alert.Description>
    </Alert>
  );
};

const UnaccompaniedChildAlert: React.FC<{}> = () => {
  const { liteTranslator } = useContext(LiteAppContext);
  const are_children_accompanied = useSelector(areChildrenAccompanied);

  if (are_children_accompanied) {
    return null;
  }

  return (
    <Alert severity="notice">
      <Alert.Title as="span">
        {liteTranslator.t("!search.passengers.error.unaccompanied-child-title")}
      </Alert.Title>
      <Alert.Description>
        {liteTranslator.t(
          "!search.passengers.error.unaccompanied-child-message"
        )}
      </Alert.Description>
    </Alert>
  );
};

export const PassengersModal: React.FC<Props> = props => {
  const { liteTranslator, features, device } = useContext(LiteAppContext);
  const store = useStore<LandingPageReduxState | ResultsReduxState>();

  const saved_passengers_state = useRef(
    store.getState().search_form.passengers
  );

  const isOpen = useIsPassengersModalOpen();

  const render_only_adults = !!features.ADULT_ONLY_SEARCH;

  const setPassengersAction = useAction(setPassengers);

  const closeModalAction = () => {
    yieldToMain().then(() => closeModal());
  };

  const mobile_footer_element = (
    <Button
      onClick={closeModalAction}
      className="backdrop-blur-none w-full"
      variant="contained"
      appearance="primary"
      id="submit-button"
      size="lg"
    >
      {liteTranslator.t("!search.passengers.done")}
    </Button>
  );

  return (
    <AdaptiveSelectorContainer
      popover_className="right-0"
      isOpen={isOpen}
      onClose={() => {
        if (device?.is_mobile) {
          setPassengersAction(saved_passengers_state.current);
        }
        closeModalAction();
        // send a message that vanilla javascript can listen to
        if (window) {
          window.postMessage(VALIDATE_FORM_MESSAGE);
        }
      }}
      anchor_element={props.input_element}
      dialog_title={liteTranslator.t("!search.passengers.title")}
      dialog_actions={mobile_footer_element}
    >
      <div className="flex flex-col gap-200">
        <VanillaTranslatedStrings />
        <TooManyPassengersAlert />
        <UnaccompaniedChildAlert />
        <div className="flex flex-col gap-300">
          <PassengerCounterInput
            adult_only={render_only_adults}
            category="adult"
          />
          {!render_only_adults && (
            <>
              <PassengerCounterInput
                adult_only={render_only_adults}
                category="child"
              />
              <PassengerAge category="child" />
              <PassengerCounterInput
                adult_only={render_only_adults}
                category="senior"
              />
              <PassengerAge category="senior" />
            </>
          )}
        </div>
        {!render_only_adults && (
          <>
            <Divider />
            <PassengerAttributesStudent />
            <Divider />
            <PassengerAttributesWheelchair />
          </>
        )}
      </div>
    </AdaptiveSelectorContainer>
  );
};

export default PassengersModal;
