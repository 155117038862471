import type { SupportedLocale } from "@config/locales";

import { image_link_template } from "../../../config/link-templates";

export default class LiteUrlBuilderService {
  public locale: SupportedLocale;

  public constructor(options: { locale: SupportedLocale }) {
    this.locale = options.locale;
  }

  private _objectToQuery(obj: { [k: string]: string | number }): string {
    return Object.keys(obj)
      .map(key => {
        return `${key}=${encodeURIComponent(obj[key])}`;
      })
      .join("&");
  }

  public image(
    path: string,
    query?: { [k: string]: string | number } | undefined
  ): string {
    const uri = new URL(path, image_link_template);
    uri.search = this._objectToQuery({ ...query, auto: "compress,format" });
    return uri.href;
  }
}
