// Module used on the client-side. Avoid importing big dependencies.

import type {
  CitySuggestion,
  SearchFormMergedState,
  SearchInfo,
  SearchInfoLocation,
  SearchParams,
  SearchQuery
} from "@app/types/search-types";

/**
 * Encode attributes from {@link SearchInfo} to be used in the path params of a URL
 * Note: `uri-js` encodes `null` as empty string so we want to encode missing
 * properties as `undefined` in order for them to not show up in the URL
 *
 * @param search
 */
export function getUrlParams(
  search: Pick<SearchInfo, "origin" | "destination">
): SearchParams {
  return {
    from: search.origin,
    to: search.destination
  };
}

/**
 * Encode attributes from {@link SearchInfo} to be used in the query string of a URL
 *
 * @param search
 */
export function getUrlQuery(
  search:
    | SearchInfo
    | (Omit<SearchFormMergedState, "passengers"> & Partial<SearchInfo>)
): SearchQuery {
  return {
    outbound_date: search.outbound_date || undefined,
    return_date: search.return_date || undefined,
    adults: encodeCount(search.adult),
    children: encodeCount(search.child),
    seniors: encodeCount(search.senior),
    adult_wheelchair: encodeCount(search.adult_wheelchair),
    child_wheelchair: encodeCount(search.child_wheelchair),
    senior_wheelchair: encodeCount(search.senior_wheelchair),
    adult_student: encodeCount(search.adult_student),
    child_student: encodeCount(search.child_student),
    senior_student: encodeCount(search.senior_student),
    child_ages: encodeAges(search.child_ages),
    senior_ages: encodeAges(search.senior_ages),
    child_wheelchair_ages: encodeAges(search.child_wheelchair_ages),
    senior_wheelchair_ages: encodeAges(search.senior_wheelchair_ages),
    child_student_ages: encodeAges(search.child_student_ages),
    senior_student_ages: encodeAges(search.senior_student_ages),
    discount_code: search.discount_code || undefined,
    outbound_id: search.outbound_id || undefined,
    cart_id: search.cart_id || undefined,
    referral_cart_id: search.referral_cart_id || undefined,
    sort_key: search.sort_key || undefined,
    origin_location: encodeLocations(
      search.origin_city,
      search.origin_location,
      search.origin_location_ids
    ),
    destination_location: encodeLocations(
      search.destination_city,
      search.destination_location,
      search.destination_location_ids
    ),
    leg_card_id: search.leg_card_id || undefined,
    vehicle_category: search.vehicle_category || undefined,
    origin_geo_entity_id: search.origin_geo_entity_id,
    destination_geo_entity_id: search.destination_geo_entity_id,
    origin_place_type: search.origin_place_type,
    destination_place_type: search.destination_place_type,
    outbound_lcf: search.outbound_leg_card_id_class_and_fare || undefined,
    return_lcf: search.return_leg_card_id_class_and_fare || undefined,
    profile_ids: search.profile_ids,
    price_mode: search.price_mode || undefined
  };
}

function encodeCount(count: number): string | undefined {
  return count !== 0 ? "" + count : undefined;
}

function encodeAges(ages: number[]): string | undefined {
  return ages && ages.length ? ages.join(",") : undefined;
}

/**
 * In order to create the query string with location IDs, location IDs must be
 * encoded in the correct format for qs.stringify.
 *
 * An example of the transformation from an array to query string is shown below.
 * city.location.id:  ['101', '102']
 * encodeLocations:   {'101': true, '102': true}
 * qs.stringify:      origin_location[101]=true&origin_location[102]=true
 *
 * @param city
 * @param location
 * @param location_ids
 */
function encodeLocations(
  city: CitySuggestion | undefined,
  location: SearchInfoLocation | undefined,
  location_ids: number[] | undefined
): { [id in string]: boolean } | undefined {
  if (city && city.location) {
    return city.location.id.reduce<Record<string, boolean>>((id_obj, id) => {
      id_obj[id] = true;
      return id_obj;
    }, {});
  } else if (location) {
    return {
      [location.id]: true
    };
  } else if (location_ids?.length) {
    return Object.fromEntries(location_ids.map(id => [`${id}`, true]));
  } else {
    return undefined;
  }
}

/**
 * Return total number of passengers
 *
 * @param {Search.PassengerBase} search
 * @returns {number}
 */
export function getPassengerCount(search: SearchInfo) {
  return search.adult + search.child + search.senior;
}
