import type { PassengerComplete } from "@app/types/search-types";

import type { PassengersAttributes } from "../passengers-constants";
import type { PayloadAction } from "@reduxjs/toolkit";

import { unassignAttributeToPassenger } from "./unassign-attribute-to-passenger";
import { PASSENGER_TYPES } from "../../../../../../constants/passengers";

export const unassignAttributeToAllPassengersReducer = (
  state: PassengerComplete,
  action: PayloadAction<{
    attribute: PassengersAttributes;
  }>
) => {
  let temp_state = { ...state };
  const {
    payload: { attribute }
  } = action;
  PASSENGER_TYPES.forEach(category => {
    const passengers_count = state[category];
    for (let index = 0; index < passengers_count; index++) {
      const fields = unassignAttributeToPassenger(temp_state, {
        attribute,
        category,
        index
      });
      temp_state = fields;
    }
  });
  return temp_state;
};
