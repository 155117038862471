import { type RefObject, useEffect, useRef } from "react";

export const DUPLICATE_EVENT_INTERVAL_MS = 300; // 300 milliseconds
/**
 * On older browser and devices, the `click` event is delayed a lot after the touchend event, to ensure
 * support of gestures.
 * If you want an interaction to be more reactive, and you don't need gestures, it may be interesting
 * to use this fastclick implementation instead of listening to click events
 *
 * @template T - The type of the HTML element.
 * @param {function(MouseEvent | TouchEvent | FocusEvent): void} callback - The callback function to be executed on the event.
 * @returns {function(T | null): void} - A ref callback function to be attached to the element.
 */
export function useFastClick<T extends HTMLElement>(
  callback: (e: MouseEvent | TouchEvent | FocusEvent) => void
): RefObject<T> {
  const ref = useRef<T>(null);
  const lastEventTimeRef = useRef(0);

  useEffect(
    function setupFastClick() {
      const element = ref.current;
      if (!element) {
        return;
      }

      let startX = 0;
      let startY = 0;

      // Deduplicates touchEnd and focus events that are triggered after a touchEnd event
      const isDuplicateEvent = () => {
        const now = Date.now();
        if (now - lastEventTimeRef.current < DUPLICATE_EVENT_INTERVAL_MS) {
          return true; // Ignore duplicate events
        }
        lastEventTimeRef.current = now;
        return false;
      };

      const touchStartHandler = (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        startX = touch.pageX;
        startY = touch.pageY;
      };

      const touchEndHandler = (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        if (
          squaredDistanceBetween(
            { x: touch.pageX, y: touch.pageY },
            { x: startX, y: startY }
          ) > 100
        ) {
          return; // Ignore scroll gestures
        }
        if (isDuplicateEvent()) {
          return;
        }

        e.preventDefault(); // Prevent click event after touchend
        callback(e);
      };

      //We support clicks for the corner case of people using a mouse with a mobile device.
      const clickHandler = (e: MouseEvent) => {
        if (isDuplicateEvent()) {
          return;
        }

        callback(e);
      };

      const focusHandler = (e: FocusEvent) => {
        if (isDuplicateEvent()) {
          return;
        }

        callback(e);
      };

      element.addEventListener("touchstart", touchStartHandler);
      element.addEventListener("touchend", touchEndHandler);
      element.addEventListener("click", clickHandler);
      element.addEventListener("focus", focusHandler);

      // Cleanup
      return () => {
        element?.removeEventListener("touchstart", touchStartHandler);
        element?.removeEventListener("touchend", touchEndHandler);
        element?.removeEventListener("click", clickHandler);
        element?.removeEventListener("focus", focusHandler);
      };
    },
    [callback]
  );

  return ref;
}

function squaredDistanceBetween(
  pointA: { x: number; y: number },
  pointB: { x: number; y: number }
): number {
  return Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2);
}
