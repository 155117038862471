import React from "react";

import type { IsoDate } from "@app/components/calendar/Calendar.types";

type translationKeys = "nextMonth" | "previousMonth" | "selectedDay";

export interface CalendarContextState {
  getWeekdays: () => IsoDate[];
  getMonthYear: (date: IsoDate) => IsoDate;
  getWeekArray: (date: IsoDate) => IsoDate[][];
  translations: Record<translationKeys, string>;
  getToday: () => IsoDate;
  addDays: (date: IsoDate, nbDaysToAdd: number) => IsoDate;
}

// @ts-expect-error
export const CalendarContext = React.createContext<CalendarContextState>();

export function useCalendarContext(): CalendarContextState {
  return React.useContext(CalendarContext);
}
