import React, { useContext } from "react";

import { useDispatch } from "react-redux";

import type { PassengerType } from "@app/constants/passengers";
import { LiteAppContext } from "@app/context/lite-app-context";
import {
  assignAttributeToPassenger,
  unassignAttributeToPassenger
} from "@app/modules/search/store/slices/search-form";
import type { PassengerComplete } from "@app/types/search-types";
import { yieldToMain } from "@app/utils/scheduler-yield";

import {
  ADULT,
  STUDENT,
  AGES_FIELDS,
  ADULT_STUDENT_CHECKBOXES
} from "../../../../modules/search/store/slices/search-form/passengers-constants";
import { PassengerAttributes } from "../passenger-attributes/";

const isDisplayed = (passengers: PassengerComplete) => {
  const { adult_student, child_student, senior_student } = passengers;
  return adult_student + child_student + senior_student > 0;
};

const isChecked = (
  passengers: PassengerComplete,
  category: PassengerType,
  index: number
) => {
  // TODO: this maybe can be removed in the future, since we implemented a strategy for independent checkboxes on issue #13030
  if (category === ADULT) {
    return passengers[ADULT_STUDENT_CHECKBOXES][index] > -1;
  }

  const [, , student_ages] = AGES_FIELDS[category][STUDENT];

  return passengers[student_ages][index] > -1;
};

export const PassengerAttributesStudent: React.FC<{}> = () => {
  const { liteTranslator } = useContext(LiteAppContext);
  const dispatch = useDispatch();

  const onChange = async (
    category: PassengerType,
    index: number,
    checked: boolean
  ) => {
    await yieldToMain();
    if (checked) {
      dispatch(
        assignAttributeToPassenger({ category, attribute: STUDENT, index })
      );
    } else {
      dispatch(
        unassignAttributeToPassenger({ category, attribute: STUDENT, index })
      );
    }
  };

  return (
    <PassengerAttributes
      name={liteTranslator.t("!search.student.toggle")}
      description={liteTranslator.t("!search.student.description")}
      isChecked={isChecked}
      isDisplayed={isDisplayed}
      onChange={onChange}
      attribute={STUDENT}
    />
  );
};
