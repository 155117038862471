import React from "react";

import clsx from "clsx";

import { IconCity, IconMarker, IconTransportationMode } from "@busbud/horizon";

type SuggestionIconSize = "sm" | "md";

export type SuggestionIconType =
  | "city"
  | "bus"
  | "plane"
  | "train"
  | "ferry"
  | "hotel";

type SuggestionIconProps = {
  type: SuggestionIconType;
  size?: SuggestionIconSize;
  hidden?: boolean;
};

export const SuggestionIcon: React.FC<SuggestionIconProps> = React.memo(
  ({
    hidden = true, // Hidden by default for SSR templates
    type,
    size = "md"
  }) => {
    const common_props = {
      size,
      className: clsx("flex-none text-icon-color-primary", {
        ["hidden"]: hidden
      }),
      ["data-icon-type"]: type
    };

    if (type === "hotel") {
      return <IconCity {...common_props} />;
    }
    if (type === "city") {
      return <IconMarker {...common_props} />;
    }

    return <IconTransportationMode variant={type} {...common_props} />;
  }
);
