import { isNumber } from "lodash";

import type { PassengerType } from "@app/constants/passengers";
import { ADULT, CHILD, SENIOR } from "@app/constants/passengers";
import type { AttributePassengerFields } from "@app/modules/search/store/slices/search-form/reducers/unassign-attribute-to-passenger";
import type { SearchStoreReducers } from "@app/types/search-types";

export const getPassengers = (state: {
  search_form: Pick<SearchStoreReducers["search_form"], "passengers">;
}) => state.search_form.passengers;

export const getTotalPassengersCount = (state: SearchStoreReducers) => {
  const { adult, child, senior } = getPassengers(state);
  return adult + child + senior;
};

export const areChildrenAccompanied = (state: SearchStoreReducers) => {
  const { adult, child, senior } = getPassengers(state);
  return child === 0 || senior > 0 || adult > 0;
};

export const canRemovePassengerWithCategory =
  (category?: PassengerType) => (state: SearchStoreReducers) => {
    const { adult, senior } = getPassengers(state);
    return (
      category === CHILD ||
      (category === ADULT && (adult > 1 || senior > 0)) ||
      (category === SENIOR && (senior > 1 || adult > 0))
    );
  };

export const isMissingAges = (state: {
  search_form: Pick<SearchStoreReducers["search_form"], "passengers">;
}) => {
  const { senior, child, senior_ages, child_ages } = getPassengers(state);
  const is_senior_missing_ages = Boolean(
    senior - senior_ages.filter(isNumber).length
  );
  const is_children_missing_ages = Boolean(
    child - child_ages.filter(isNumber).length
  );

  return is_senior_missing_ages || is_children_missing_ages;
};

export const getLegacySearchFormPassengers = (
  state: SearchStoreReducers
): AttributePassengerFields => {
  const passengers = getPassengers(state);

  const child_wheelchair_ages =
    passengers.child_wheelchair_ages.filter(isValidAge);
  const child_student_ages = passengers.child_student_ages.filter(isValidAge);

  const senior_wheelchair_ages =
    passengers.senior_wheelchair_ages.filter(isValidAge);
  const senior_student_ages = passengers.senior_student_ages.filter(isValidAge);

  return {
    ...passengers,
    child_wheelchair_ages,
    child_student_ages,
    senior_wheelchair_ages,
    senior_student_ages
  };
};

export const isValidAge = (age: number) => age > -1;
