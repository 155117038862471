import type { FC } from "react";
import React from "react";

import type { AdaptiveSelectorProps } from "@app/components/adaptive-selector-container/types";
import { Dialog } from "@app/components/dialog";
import { yieldToMain } from "@app/utils/scheduler-yield";

export const MobileSelectorContainer: FC<AdaptiveSelectorProps> = ({
  children,
  dialog_title,
  dialog_actions,
  onClose,
  isOpen = true
}) => {
  const onCloseModal = React.useCallback(() => {
    yieldToMain().then(() => onClose());
  }, [onClose]);

  return (
    <Dialog
      onClose={onCloseModal}
      isOpen={isOpen}
      className="m-0 max-h-full max-w-full rounded-none"
    >
      <div className="flex h-full flex-col">
        <Dialog.Header onClose={onCloseModal}>{dialog_title}</Dialog.Header>
        <Dialog.Body className="grow">{children}</Dialog.Body>
        {!!dialog_actions && <Dialog.Footer>{dialog_actions}</Dialog.Footer>}
      </div>
    </Dialog>
  );
};
