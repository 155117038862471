import type { FC, PropsWithChildren } from "react";
import React from "react";

import { Heading, type HeadingProps } from "@busbud/horizon";

type DialogTitleProps = Omit<HeadingProps, "as" | "size">;

export const DialogTitle: FC<PropsWithChildren<DialogTitleProps>> = props => (
  <Heading as="h1" size="xs" {...props} />
);
