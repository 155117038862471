import type { FC, PropsWithChildren, ReactNode } from "react";
import React from "react";

import { mergeClasses } from "@busbud/horizon";

interface DialogFooterProps {
  as?: "div" | "section";
  className?: string;
  slotEnd?: ReactNode;
  slotStart?: ReactNode;
}

export const DialogFooter: FC<PropsWithChildren<DialogFooterProps>> = ({
  as: Component = "div",
  children,
  className,
  slotEnd,
  slotStart,
  ...restProps
}) => {
  return (
    <Component
      className={mergeClasses(
        "flex items-center border-t-width-sm border-color-primary p-200",
        className
      )}
      {...restProps}
    >
      {!!slotStart && <div className="shrink-0">{slotStart}</div>}
      {/* `min-w-0 ` forces the block to shrink when the element is full */}
      <div className="min-w-0 grow px-100 py-075">{children}</div>
      {!!slotEnd && <div className="shrink-0">{slotEnd}</div>}
    </Component>
  );
};
