import type { FC, PropsWithChildren, ReactNode } from "react";
import React from "react";

import { ButtonIcon, IconXMark, mergeClasses } from "@busbud/horizon";

import { type DialogProps } from "../dialog";

interface DialogHeaderProps extends Pick<DialogProps, "onClose"> {
  as?: "div" | "section";
  className?: string;
  slotStart?: ReactNode;
}

export const DialogHeader: FC<PropsWithChildren<DialogHeaderProps>> = ({
  as: Component = "div",
  children,
  className,
  slotStart,
  onClose,
  ...restProps
}) => {
  return (
    <Component
      className={mergeClasses(
        "flex items-center border-b-width-sm border-color-primary p-200",
        className
      )}
      {...restProps}
    >
      {!!slotStart && <div className="shrink-0">{slotStart}</div>}
      {/* `min-w-0 ` forces the block to shrink when the element is full */}
      <div className="min-w-0 grow px-100 py-075">{children}</div>
      <ButtonIcon className="shrink-0" onClick={onClose}>
        <IconXMark size="md" />
      </ButtonIcon>
    </Component>
  );
};
