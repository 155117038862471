import { createContext } from "react";

import type { PageEntity } from "@app/lib/page-entity";
import Translator, {
  type LiteTranslatorService
} from "@app/services/translator";
import type { ValidLiteTranslationKey } from "@app/services/translator-lite.keys";
import LiteUrlBuilderService from "@app/services/url-builders/lite-url-builder";
import type UrlBuilderService from "@app/services/url-builders/url-builder";
import type { Tracker } from "@app/tracking/tracker";
import type { DeviceInfo } from "@app/types/device";
import type { Features } from "@app/types/experiments";

import type { SupportedLocale } from "@config/locales";

export interface LiteAppContext {
  entity: PageEntity | null;
  locale: SupportedLocale;
  liteTranslator: LiteTranslatorService;
  features: Partial<Features>;
  device: DeviceInfo | null;
  tracker: Tracker | null;
  urlBuilder: LiteUrlBuilderService | UrlBuilderService;
}

export const LiteAppContext = createContext<LiteAppContext>({
  entity: null,
  locale: "en",
  features: {},
  liteTranslator: new Translator<ValidLiteTranslationKey, "lite">(),
  device: null,
  tracker: null,
  urlBuilder: new LiteUrlBuilderService({
    locale: "en"
  })
});

export const LiteAppContextProvider = LiteAppContext.Provider;
