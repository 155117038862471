import type { PassengerComplete } from "@app/types/search-types";

import type { ADULT, PassengersCount } from "../passengers-constants";
import type { PayloadAction } from "@reduxjs/toolkit";

import { CHILD, CHILD_AGES, SENIOR_AGES } from "../passengers-constants";

export const setPassengerAgeReducer = (
  state: PassengerComplete,
  action: PayloadAction<{
    category: Exclude<PassengersCount, typeof ADULT>;
    age: number;
    index: number;
  }>
) => {
  const { category, age, index } = action.payload;

  const pax_ages = category === CHILD ? CHILD_AGES : SENIOR_AGES;
  const pax_wheelchair_ages = `${category}_wheelchair_ages` as const;
  const pax_student_ages = `${category}_student_ages` as const;

  const fields = {
    [pax_ages]: [...state[pax_ages]],
    [pax_wheelchair_ages]: [...state[pax_wheelchair_ages]],
    [pax_student_ages]: [...state[pax_student_ages]]
  };

  fields[pax_ages][index] = age;

  if (fields[pax_wheelchair_ages][index] > -1) {
    fields[pax_wheelchair_ages][index] = age;
  }

  if (fields[pax_student_ages][index] > -1) {
    fields[pax_student_ages][index] = age;
  }

  return {
    ...state,
    ...fields
  };
};
