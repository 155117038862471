import React, { useContext } from "react";

import type { PassengerType } from "@app/constants/passengers";
import { LiteAppContext } from "@app/context/lite-app-context";

import { PassengerCounter } from "./passenger-counter";

interface Props {
  adult_only: boolean;
  category: PassengerType;
}

export const PassengerCounterInput: React.FC<Props> = props => {
  const { liteTranslator } = useContext(LiteAppContext);
  const { adult_only, category } = props;
  const { label, hint } = React.useMemo(() => {
    switch (category) {
      case "adult":
        return {
          label: adult_only
            ? liteTranslator.t("!landing.input-label.passengers")
            : liteTranslator.t("!landing.input-label.adults"),
          hint: adult_only
            ? ""
            : liteTranslator.t("!landing.input-label.adult-hint")
        };
      case "child":
        return {
          label: liteTranslator.t("!landing.input-label.youth"),
          hint: liteTranslator.t("!landing.input-label.youth-hint")
        };
      case "senior":
        return {
          label: liteTranslator.t("!landing.input-label.seniors"),
          hint: liteTranslator.t("!landing.input-label.senior-hint")
        };
      default:
        return { label: "", hint: "" };
    }
  }, [category, adult_only, liteTranslator]);

  if (adult_only && category !== "adult") {
    return null;
  }

  return (
    <PassengerCounter
      id={`${category}-count-container`}
      label={label}
      category={category}
      hint={hint}
    />
  );
};
