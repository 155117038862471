import type { SuggestionIconType } from "@app/modules/search/suggestion/suggestion-icon";

const location_icons = {
  airport: "plane",
  train_station: "train",
  port: "ferry",
  hotel: "hotel"
} as const satisfies Record<string, SuggestionIconType>;

type LocationIcon = keyof typeof location_icons;

export const getSuggestionLocationIcon = (
  stop_type: LocationIcon | (string & {}) | undefined
) => {
  return location_icons[stop_type as LocationIcon] || "bus";
};
