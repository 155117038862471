"use strict";

const autobus_in_russian = encodeURI("автобус");
const train_in_russian = encodeURI("поезд");
const autobus_company_in_russian = encodeURI("автобусная-компания");

export const home_link_template = "/{locale}";
export const busbud_home_link_template = "https://www.busbud.com/{locale}";
export const about_link_template = "/{locale}/about";
export const about_partners_link_template = "/{locale}/about/partners";
export const affiliate_link_template = {
  de: "https://www.busbud.com/en/affiliate",
  en: "https://www.busbud.com/en/affiliate",
  "en-ca": "https://www.busbud.com/en-ca/affiliate",
  "en-gb": "https://www.busbud.com/en/affiliate",
  es: "https://www.busbud.com/es/affiliate",
  "es-419": "https://www.busbud.com/es/affiliate",
  "es-mx": "https://www.busbud.com/es/affiliate",
  fr: "https://www.busbud.com/en/affiliate",
  "fr-ca": "https://www.busbud.com/fr-ca/affiliate",
  it: "https://www.busbud.com/en/affiliate",
  nl: "https://www.busbud.com/en/affiliate",
  pl: "https://www.busbud.com/en/affiliate",
  pt: "https://www.busbud.com/en/affiliate",
  "pt-pt": "https://www.busbud.com/en/affiliate",
  ru: "https://www.busbud.com/en/affiliate",
  sv: "https://www.busbud.com/en/affiliate",
  tr: "https://www.busbud.com/en/affiliate",
  zh: "https://www.busbud.com/en/affiliate"
};
export const distribution_link_template = {
  de: "https://www.busbud.com/en/distribution",
  en: "https://www.busbud.com/en/distribution",
  "en-ca": "https://www.busbud.com/en-ca/distribution",
  "en-gb": "https://www.busbud.com/en/distribution",
  es: "https://www.busbud.com/es/distribution",
  "es-419": "https://www.busbud.com/es/distribution",
  "es-mx": "https://www.busbud.com/es/distribution",
  fr: "https://www.busbud.com/fr/distribution",
  "fr-ca": "https://www.busbud.com/fr-ca/distribution",
  it: "https://www.busbud.com/en/distribution",
  nl: "https://www.busbud.com/en/distribution",
  pl: "https://www.busbud.com/en/distribution",
  pt: "https://www.busbud.com/en/distribution",
  "pt-pt": "https://www.busbud.com/en/distribution",
  ru: "https://www.busbud.com/en/distribution",
  sv: "https://www.busbud.com/en/distribution",
  tr: "https://www.busbud.com/en/distribution",
  zh: "https://www.busbud.com/en/distribution"
};
export const terms_of_use_link_template =
  "https://www.busbud.com/{locale}/terms-of-use/";
export const privacy_link_template =
  "https://www.busbud.com/{locale}/privacy-policy/";
export const refunds_link_template =
  "https://www.busbud.com/{locale}/refund-policy/";
export const press_link_template = "/{locale}/about/press";
export const careers_link_template = "/{locale}/careers/";
export const bus_partners_link_template = "/partner/supply/";
export const impressum_link_template = "/{locale}/about/impressum";
export const instagram_link_template = "https://www.instagram.com/busbud/";
export const facebook_link_template = "https://www.facebook.com/Busbud";
export const twitter_link_template = "https://twitter.com/Busbud";
export const affiliate_products_link_template = {
  // TODO: affiliate products are not completely localized
  de: "https://www.busbud.com/partner",
  en: "https://www.busbud.com/partner",
  "en-ca": "https://www.busbud.com/partner",
  "en-gb": "https://www.busbud.com/partner",
  es: "https://www.busbud.com/partner/es/",
  "es-419": "https://www.busbud.com/partner",
  "es-mx": "https://www.busbud.com/partner",
  fr: "https://www.busbud.com/partner/fr/",
  "fr-ca": "https://www.busbud.com/partner/fr/",
  it: "https://www.busbud.com/partner",
  nl: "https://www.busbud.com/partner",
  pl: "https://www.busbud.com/partner",
  pt: "https://www.busbud.com/partner",
  "pt-pt": "https://www.busbud.com/partner",
  ru: "https://www.busbud.com/partner",
  sv: "https://www.busbud.com/partner",
  tr: "https://www.busbud.com/partner",
  zh: "https://www.busbud.com/partner"
};
export const contact_link_template = "/{locale}/contact";
export const blog_link_template = {
  // TODO: Blog is not completely localized
  de: "https://www.busbud.com/blog/",
  en: "https://www.busbud.com/blog/",
  "en-ca": "https://www.busbud.com/blog/",
  "en-gb": "https://www.busbud.com/blog/",
  es: "https://www.busbud.com/blog/es/pagina-principal/",
  "es-419": "https://www.busbud.com/blog/es/pagina-principal/",
  "es-mx": "https://www.busbud.com/blog/es/pagina-principal/",
  fr: "https://www.busbud.com/blog/fr/accueil/",
  "fr-ca": "https://www.busbud.com/blog/fr/accueil/",
  it: "https://www.busbud.com/blog/",
  nl: "https://www.busbud.com/blog/",
  pl: "https://www.busbud.com/blog/",
  pt: "https://www.busbud.com/blog/pagina-inicial/",
  "pt-pt": "https://www.busbud.com/blog/pagina-inicial/",
  ru: "https://www.busbud.com/blog/",
  sv: "https://www.busbud.com/blog/",
  tr: "https://www.busbud.com/blog/",
  zh: "https://www.busbud.com/blog/"
};
export const stop_keyword_link_template = {
  de: "/de/{keyword}/s/{id}",
  en: "/en/{keyword}/s/{id}",
  "en-ca": "/en-ca/{keyword}/s/{id}",
  "en-gb": "/en-gb/{keyword}/s/{id}",
  es: "/es/{keyword}/s/{id}",
  "es-419": "/es-419/{keyword}/s/{id}",
  "es-mx": "/es-mx/{keyword}/s/{id}",
  fr: "/fr/{keyword}/s/{id}",
  "fr-ca": "/fr-ca/{keyword}/s/{id}",
  it: "/it/{keyword}/s/{id}",
  nl: "/nl/{keyword}/s/{id}",
  pl: "/pl/{keyword}/s/{id}",
  pt: "/pt/{keyword}/s/{id}",
  "pt-pt": "/pt-pt/{keyword}/s/{id}",
  ru: "/ru/{keyword}/s/{id}",
  sv: "/sv/{keyword}/s/{id}",
  tr: "/tr/{keyword}/s/{id}",
  zh: "/zh/{keyword}/s/{id}"
};
export const partner_link_template = {
  de: "/de/busunternehmen/{url_form}",
  en: "/en/bus-company/{url_form}",
  "en-ca": "/en-ca/bus-company/{url_form}",
  "en-gb": "/en-gb/bus-company/{url_form}",
  es: "/es/empresa-de-autobuses/{url_form}",
  "es-419": "/es-419/empresa-de-autobuses/{url_form}",
  "es-mx": "/es-mx/empresa-de-autobuses/{url_form}",
  fr: "/fr/compagnie-de-bus/{url_form}",
  "fr-ca": "/fr-ca/compagnie-de-bus/{url_form}",
  it: "/it/operatore-di-autobus/{url_form}",
  nl: "/nl/busmaatschappij/{url_form}",
  pl: "/pl/firma-autobusowa/{url_form}",
  pt: "/pt/empresa-de-onibus/{url_form}",
  "pt-pt": "/pt-pt/empresa-de-autocarros/{url_form}",
  sv: "/sv/bussbolag/{url_form}",
  ru: `/ru/${autobus_company_in_russian}/{url_form}`,
  tr: "/tr/otobus-firmalari/{url_form}",
  zh: "/zh/bus-company/{url_form}"
};
export const operator_link_template = "/{locale}/o/{url_form}";
export const operator_awards_template = "/{language}/operator-awards";
export const results_link_template = {
  de: "/de/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  en: "/en/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "en-ca":
    "/en-ca/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "en-gb":
    "/en-gb/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  es: "/es/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "es-419":
    "/es-419/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "es-mx":
    "/es-mx/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  fr: "/fr/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "fr-ca":
    "/fr-ca/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  it: "/it/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  nl: "/nl/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  pl: "/pl/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  pt: "/pt/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  "pt-pt":
    "/pt-pt/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  ru: "/ru/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  sv: "/sv/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  tr: "/tr/bus-schedules-results/{origin_geohash}/{destination_geohash}",
  zh: "/zh/bus-schedules-results/{origin_geohash}/{destination_geohash}"
};
export const deeplink_link_template = {
  de: "/de/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  en: "/en/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "en-ca":
    "/en-ca/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "en-gb":
    "/en-gb/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  es: "/es/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "es-419":
    "/es-419/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "es-mx":
    "/es-mx/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  fr: "/fr/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "fr-ca":
    "/fr-ca/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  it: "/it/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  nl: "/nl/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  pl: "/pl/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  pt: "/pt/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  "pt-pt":
    "/pt-pt/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  ru: "/ru/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  sv: "/sv/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  tr: "/tr/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
  zh: "/zh/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}"
};
export const route_link_template = {
  de: "/de/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  en: "/en/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "en-ca":
    "/en-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "en-gb":
    "/en-gb/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  es: "/es/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "es-419":
    "/es-419/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "es-mx":
    "/es-mx/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  fr: "/fr/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "fr-ca":
    "/fr-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  it: "/it/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  nl: "/nl/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  pl: "/pl/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  pt: "/pt/onibus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "pt-pt":
    "/pt-pt/autocarro-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  sv: "/sv/buss-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  ru: `/ru/${autobus_in_russian}-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}`,
  tr: "/tr/otobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  zh: "/zh/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}"
};
export const route_sem_link_template = {
  de: "/de/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  en: "/en/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "en-ca":
    "/en-ca/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "en-gb":
    "/en-gb/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  es: "/es/{origin_country}-{destination_country}/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "es-419":
    "/es-419/{origin_country}-{destination_country}/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "es-mx":
    "/es-mx/{origin_country}-{destination_country}/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  fr: "/fr/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "fr-ca":
    "/fr-ca/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  it: "/it/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  nl: "/nl/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  pl: "/pl/{origin_country}-{destination_country}/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  pt: "/pt/{origin_country}-{destination_country}/onibus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  "pt-pt":
    "/pt-pt/{origin_country}-{destination_country}/autocarro-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  sv: "/sv/{origin_country}-{destination_country}/buss-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  ru: `/ru/{origin_country}-{destination_country}/${autobus_in_russian}-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}`,
  tr: "/tr/{origin_country}-{destination_country}/otobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
  zh: "/zh/{origin_country}-{destination_country}/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}"
};
export const point_of_interest_link_template = {
  de: "/de/poi/{keyword}/{id}",
  en: "/en/poi/{keyword}/{id}",
  "en-ca": "/en-ca/poi/{keyword}/{id}",
  "en-gb": "/en-gb/poi/{keyword}/{id}",
  es: "/es/poi/{keyword}/{id}",
  "es-419": "/es-419/poi/{keyword}/{id}",
  "es-mx": "/es-mx/poi/{keyword}/{id}",
  fr: "/fr/poi/{keyword}/{id}",
  "fr-ca": "/fr-ca/poi/{keyword}/{id}",
  it: "/it/poi/{keyword}/{id}",
  nl: "/nl/poi/{keyword}/{id}",
  pl: "/pl/poi/{keyword}/{id}",
  pt: "/pt/poi/{keyword}/{id}",
  "pt-pt": "/pt-pt/poi/{keyword}/{id}",
  sv: "/sv/poi/{keyword}/{id}",
  ru: "/ru/poi/{keyword}/{id}",
  tr: "/tr/poi/{keyword}/{id}",
  zh: "/zh/poi/{keyword}/{id}"
};
export const point_of_interest_route_link_template = {
  de: "/de/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  en: "/en/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "en-ca":
    "/en-ca/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "en-gb":
    "/en-gb/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  es: "/es/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "es-419":
    "/es-419/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "es-mx":
    "/es-mx/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  fr: "/fr/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "fr-ca":
    "/fr-ca/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  it: "/it/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  nl: "/nl/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  pl: "/pl/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  pt: "/pt/onibus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  "pt-pt":
    "/pt-pt/autocarro-{origin_name}-{destination_name}/i/{origin}-{destination}",
  sv: "/sv/buss-{origin_name}-{destination_name}/i/{origin}-{destination}",
  ru: `/ru/${autobus_in_russian}-{origin_name}-{destination_name}/i/{origin}-{destination}`,
  tr: "/tr/otobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
  zh: "/zh/bus-{origin_name}-{destination_name}/i/{origin}-{destination}"
};
export const multimodal_route_link_template = {
  de: "/de/{origin_name}-{destination_name}/m/{origin}-{destination}",
  en: "/en/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "en-ca": "/en-ca/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "en-gb": "/en-gb/{origin_name}-{destination_name}/m/{origin}-{destination}",
  es: "/es/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "es-419": "/es-419/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "es-mx": "/es-mx/{origin_name}-{destination_name}/m/{origin}-{destination}",
  fr: "/fr/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "fr-ca": "/fr-ca/{origin_name}-{destination_name}/m/{origin}-{destination}",
  it: "/it/{origin_name}-{destination_name}/m/{origin}-{destination}",
  nl: "/nl/{origin_name}-{destination_name}/m/{origin}-{destination}",
  pl: "/pl/{origin_name}-{destination_name}/m/{origin}-{destination}",
  pt: "/pt/{origin_name}-{destination_name}/m/{origin}-{destination}",
  "pt-pt": "/pt-pt/{origin_name}-{destination_name}/m/{origin}-{destination}",
  sv: "/sv/{origin_name}-{destination_name}/m/{origin}-{destination}",
  ru: "/ru/{origin_name}-{destination_name}/m/{origin}-{destination}",
  tr: "/tr/{origin_name}-{destination_name}/m/{origin}-{destination}",
  zh: "/zh/{origin_name}-{destination_name}/m/{origin}-{destination}"
};
export const train_route_keyword_link_template = {
  de: "/de/zug-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  en: "/en/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "en-ca":
    "/en-ca/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "en-gb":
    "/en-gb/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  es: "/es/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "es-419":
    "/es-419/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "es-mx":
    "/es-mx/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  fr: "/fr/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "fr-ca":
    "/fr-ca/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  it: "/it/treno-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  nl: "/nl/trein-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  pl: "/pl/pociag-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  pt: "/pt/trem-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  "pt-pt":
    "/pt-pt/trem-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  ru: `/ru/${train_in_russian}-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}`,
  sv: "/sv/vlak-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  tr: "/tr/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
  zh: "/zh/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}"
};
export const bus_tickets_page_link_template = {
  de: "/de/bt/bahn-tickets",
  en: "/en/bt/bus-tickets",
  "en-ca": "/en-ca/bt/bus-tickets",
  "en-gb": "/en-gb/bt/bus-tickets",
  es: "/es/bt/billetes-de-autobus",
  "es-419": "/es-419/bt/billetes-de-autobus",
  "es-mx": "/es-mx/bt/billetes-de-autobus",
  fr: "/fr/bt/tickets-de-bus",
  "fr-ca": "/fr-ca/bt/tickets-de-bus",
  it: "/it/bt/bigles-di-autobus",
  nl: "/nl/bt/bus-tickets",
  pl: "/pl/bt/bilety-autobusowe",
  pt: "/pt/bt/passagens-de-onibus",
  "pt-pt": "/pt-pt/bt/bilhetes-de-autocarro",
  ru: "/ru/bt/bus-tickets",
  sv: "/sv/bt/bussbiljetter",
  tr: "/tr",
  zh: "/zh/bt/bus-tickets"
};
export const train_tickets_page_link_template = {
  de: "/de/tt/zug-tickets",
  en: "/en/tt/train-tickets",
  "en-ca": "/en-ca/tt/train-tickets",
  "en-gb": "/en-gb/tt/train-tickets",
  es: "/es/tt/billetes-de-tren",
  "es-419": "/es-419/tt/billetes-de-tren",
  "es-mx": "/es-mx/tt/billetes-de-tren",
  fr: "/fr/tt/tickets-de-train",
  "fr-ca": "/fr-ca/tt/tickets-de-train",
  it: "/it/tt/biglietti-di-treno",
  nl: "/nl/tt/trein-tickets",
  pl: "/pl/tt/bilety-pociagu",
  pt: "/pt/tt/passagens-de-trem",
  "pt-pt": "/pt-pt/tt/bilhetes-de-tren",
  ru: "/ru/tt/train-tickets",
  sv: "/sv/tt/tag-biljetter",
  tr: "/tr/tt/otobus-biletleri",
  zh: "/zh/tt/train-tickets"
};
export const route_androidapp_link_template = {
  de: "android-app://com.busbud.android/https/www.busbud.com/de/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  en: "android-app://com.busbud.android/https/www.busbud.com/en/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "en-ca":
    "android-app://com.busbud.android/https/www.busbud.com/en-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "en-gb":
    "android-app://com.busbud.android/https/www.busbud.com/en-gb/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  es: "android-app://com.busbud.android/https/www.busbud.com/es/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "es-419":
    "android-app://com.busbud.android/https/www.busbud.com/es-419/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "es-mx":
    "android-app://com.busbud.android/https/www.busbud.com/es-mx/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  fr: "android-app://com.busbud.android/https/www.busbud.com/fr/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "fr-ca":
    "android-app://com.busbud.android/https/www.busbud.com/fr-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  it: "android-app://com.busbud.android/https/www.busbud.com/it/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  nl: "android-app://com.busbud.android/https/www.busbud.com/nl/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  pl: "android-app://com.busbud.android/https/www.busbud.com/pl/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  pt: "android-app://com.busbud.android/https/www.busbud.com/pt/onibus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  "pt-pt":
    "android-app://com.busbud.android/https/www.busbud.com/pt-pt/autocarro-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  ru: "android-app://com.busbud.android/https/www.busbud.com/ru/avtobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  sv: "android-app://com.busbud.android/https/www.busbud.com/sv/buss-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  tr: "android-app://com.busbud.android/https/www.busbud.com/tr/otobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
  zh: "android-app://com.busbud.android/https/www.busbud.com/zh/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}"
};
export const city_link_template = {
  de: "/de/bus-{name}/c/{geohash}",
  en: "/en/bus-{name}/c/{geohash}",
  "en-ca": "/en-ca/bus-{name}/c/{geohash}",
  "en-gb": "/en-gb/bus-{name}/c/{geohash}",
  es: "/es/autobus-{name}/c/{geohash}",
  "es-419": "/es-419/autobus-{name}/c/{geohash}",
  "es-mx": "/es-mx/autobus-{name}/c/{geohash}",
  fr: "/fr/bus-{name}/c/{geohash}",
  "fr-ca": "/fr-ca/bus-{name}/c/{geohash}",
  it: "/it/bus-{name}/c/{geohash}",
  nl: "/nl/bus-{name}/c/{geohash}",
  pl: "/pl/autobus-{name}/c/{geohash}",
  pt: "/pt/onibus-{name}/c/{geohash}",
  "pt-pt": "/pt-pt/autocarro-{name}/c/{geohash}",
  ru: `/ru/${autobus_in_russian}-{name}/c/{geohash}`,
  sv: "/sv/buss-{name}/c/{geohash}",
  tr: "/tr/otobus-{name}/c/{geohash}",
  zh: "/zh/bus-{name}/c/{geohash}"
};
export const trains_to_link_template = {
  de: "/de/zug-{name}/ct/{geohash}",
  en: "/en/train-{name}/ct/{geohash}",
  "en-ca": "/en-ca/train-{name}/ct/{geohash}",
  "en-gb": "/en-gb/train-{name}/ct/{geohash}",
  es: "/es/tren-{name}/ct/{geohash}",
  "es-419": "/es-419/tren-{name}/ct/{geohash}",
  "es-mx": "/es-mx/tren-{name}/ct/{geohash}",
  fr: "/fr/train-{name}/ct/{geohash}",
  "fr-ca": "/fr-ca/train-{name}/ct/{geohash}",
  it: "/it/treno-{name}/ct/{geohash}",
  nl: "/nl/trein-{name}/ct/{geohash}",
  pl: "/pl/pociag-{name}/ct/{geohash}",
  pt: "/pt/trem-{name}/ct/{geohash}",
  "pt-pt": "/pt-pt/trem-{name}/ct/{geohash}",
  ru: `/ru/${train_in_russian}-{name}/ct/{geohash}`,
  sv: "/sv/vlak-{name}/ct/{geohash}",
  tr: "/tr/tren-{name}/ct/{geohash}",
  zh: "/zh/train-{name}/ct/{geohash}"
};
export const service_disruption_link_template = {
  de: "/de/reiseunterbrechungen-streiks/td/{country_code2}",
  en: "/en/travel-disruptions-strikes/td/{country_code2}",
  "en-ca": "/en-ca/travel-disruptions-strikes/td/{country_code2}",
  "en-gb": "/en-gb/travel-disruptions-strikes/td/{country_code2}",
  es: "/es/interrupciones-viaje-huelgas/td/{country_code2}",
  "es-419": "/es-419/interrupciones-viaje-huelgas/td/{country_code2}",
  "es-mx": "/es-mx/interrupciones-viaje-huelgas/td/{country_code2}",
  fr: "/fr/perturbations-voyage-greves/td/{country_code2}",
  "fr-ca": "/fr-ca/perturbations-voyage-greves/td/{country_code2}",
  it: "/it/interruzioni-viaggio-scioperi/td/{country_code2}",
  nl: "/nl/reisonderbrekingen-stakingen/td/{country_code2}",
  pl: "/pl/zaklocenia-podrozy-strajki/td/{country_code2}",
  pt: "/pt/interrupcoes-viagem-greves/td/{country_code2}",
  "pt-pt": "/pt-pt/interrupcoes-viagem-greves/td/{country_code2}",
  ru: "/ru/travel-disruptions-strikes/td/{country_code2}",
  sv: "/sv/reseavbrott-strejker/td/{country_code2}",
  tr: "/tr/seyahat-aksakliklari-grevler/td/{country_code2}",
  zh: "/zh/travel-disruptions-strikes/td/{country_code2}"
};
export const seasonal_page_link_template = {
  de: "/de/sl/{seasonal_page_slug}",
  en: "/en/sl/{seasonal_page_slug}",
  "en-ca": "/en-ca/sl/{seasonal_page_slug}",
  "en-gb": "/en-gb/sl/{seasonal_page_slug}",
  es: "/es/sl/{seasonal_page_slug}",
  "es-419": "/es-419/sl/{seasonal_page_slug}",
  "es-mx": "/es-mx/sl/{seasonal_page_slug}",
  fr: "/fr/sl/{seasonal_page_slug}",
  "fr-ca": "/fr-ca/sl/{seasonal_page_slug}",
  it: "/it/sl/{seasonal_page_slug}",
  nl: "/nl/sl/{seasonal_page_slug}",
  pl: "/pl/sl/{seasonal_page_slug}",
  pt: "/pt/sl/{seasonal_page_slug}",
  "pt-pt": "/pt-pt/sl/{seasonal_page_slug}",
  ru: "/ru/sl/{seasonal_page_slug}",
  sv: "/sv/sl/{seasonal_page_slug}",
  tr: "/tr/sl/{seasonal_page_slug}",
  zh: "/zh/sl/{seasonal_page_slug}"
};
export const city_sem_link_template = {
  de: "/de/{country}/bus-{name}/c-sem/{geohash}",
  en: "/en/{country}/bus-{name}/c-sem/{geohash}",
  "en-ca": "/en-ca/{country}/bus-{name}/c-sem/{geohash}",
  "en-gb": "/en-gb/{country}/bus-{name}/c-sem/{geohash}",
  es: "/es/{country}/autobus-{name}/c-sem/{geohash}",
  "es-419": "/es-419/{country}/autobus-{name}/c-sem/{geohash}",
  "es-mx": "/es-mx/{country}/autobus-{name}/c-sem/{geohash}",
  fr: "/fr/{country}/bus-{name}/c-sem/{geohash}",
  "fr-ca": "/fr-ca/{country}/bus-{name}/c-sem/{geohash}",
  it: "/it/{country}/bus-{name}/c-sem/{geohash}",
  nl: "/nl/{country}/bus-{name}/c-sem/{geohash}",
  pl: "/pl/{country}/autobus-{name}/c-sem/{geohash}",
  pt: "/pt/{country}/onibus-{name}/c-sem/{geohash}",
  "pt-pt": "/pt-pt/{country}/autocarro-{name}/c-sem/{geohash}",
  ru: `/ru/{country}/${autobus_in_russian}-{name}/c-sem/{geohash}`,
  sv: "/sv/{country}/buss-{name}/c-sem/{geohash}",
  tr: "/tr/{country}/otobus-{name}/c-sem/{geohash}",
  zh: "/zh/{country}/bus-{name}/c-sem/{geohash}"
};
export const sitemap_link_template = {
  routes: {
    index: "/{locale}/sitemap/wr/0",
    country: "/{locale}/sitemap/c/{country_code2}"
  },
  stops: {
    index: "/{locale}/sitemap/ws/0",
    country: "/{locale}/sitemap/cs/{country_code2}",
    city: "/{locale}/sitemap/is/{city_geohash}"
  },
  points_of_interest: {
    index: "/{locale}/sitemap/wpoi/0",
    country: "/{locale}/sitemap/cpoi/{country_code2}",
    city: "/{locale}/sitemap/ipoi/{city_geohash}"
  },
  cities: {
    index: "/{locale}/sitemap/wc/0",
    country: "/{locale}/sitemap/cc/{country_code2}"
  },
  trains_to: {
    index: "/{locale}/sitemap/wct/0",
    country: "/{locale}/sitemap/cct/{country_code2}"
  },
  partners: {
    index: "/{locale}/sitemap/wp/0"
  },
  service_disruptions: {
    index: "/{locale}/sitemap/td/0"
  }
};
export const sitemap_keyword_link_template = {
  routes: {
    index: "/{locale}/sitemap/{keyword}/wr/0",
    country: "/{locale}/sitemap/{keyword}/c/{country_code2}"
  },
  stops: {
    index: "/{locale}/sitemap/{keyword}/ws/0",
    country: "/{locale}/sitemap/{keyword}/cs/{country_code2}",
    city: "/{locale}/sitemap/{keyword}/is/{city_geohash}"
  },
  cities: {
    country: "/{locale}/sitemap/{keyword}/cc/{country_code2}"
  },
  partners: {
    index: "/{locale}/sitemap/{keyword}/wp/0"
  }
};
export const country_link_template = "/{locale}/country/{country_code2}";
export const help_link_template = {
  de: "https://help.busbud.com/hc/de",
  en: "https://help.busbud.com/hc/en-us",
  "en-ca": "https://help.busbud.com/hc/en-us",
  "en-gb": "https://help.busbud.com/hc/en-us",
  es: "https://help.busbud.com/hc/es",
  "es-419": "https://help.busbud.com/hc/es",
  "es-mx": "https://help.busbud.com/hc/es",
  fr: "https://help.busbud.com/hc/fr",
  "fr-ca": "https://help.busbud.com/hc/fr",
  it: "https://help.busbud.com/hc/it",
  nl: "https://help.busbud.com/hc/en-us",
  pl: "https://help.busbud.com/hc/en-us",
  pt: "https://help.busbud.com/hc/pt-br",
  "pt-pt": "https://help.busbud.com/hc/pt-br",
  ru: "https://help.busbud.com/hc/ru",
  sv: "https://help.busbud.com/hc/en-us",
  tr: "https://help.busbud.com/hc/tr",
  zh: "https://help.busbud.com/hc/en-us"
};
export const search_link_template = {
  submit: "/{locale}/search/submit"
};
export const checkout_link_template = {
  index: "/{locale}/checkout",
  referral: "/{locale}/checkout/referral/{referral_cart_id}"
};
export const thank_you_link_template = {
  index: "/{locale}/thank-you/{purchase_id}"
};
export const purchase_unsuccessful_link_template = {
  index: "/{locale}/purchase-unsuccessful/{purchase_id}"
};
export const account_link_template = {
  logout: "/{locale}/auth/logout",
  loyalty_profile: "/{locale}/account/profile",
  passenger_profiles: "/{locale}/account/passenger-profiles",
  profile: "/{locale}/account/trips",
  settings: "/{locale}/account/settings",
  signin: "/{locale}/account/signin",
  signup: "/{locale}/account/signup",
  trip: "/{locale}/account/trips/{purchase_id}",
  trips: "/{locale}/account/trips",
  forgot_password: "/{locale}/account/forgot-password"
};
export const error404_link_template = "/{locale}/404";
export const image_link_template = "https://busbud.imgix.net/";
export const typeform_survey_link_template = {
  rating: {
    de: "https://busbudsurveys.typeform.com/to/W6fwAB",
    en: "https://busbudsurveys.typeform.com/to/iivPVl",
    "en-ca": "https://busbudsurveys.typeform.com/to/iivPVl",
    "en-gb": "https://busbudsurveys.typeform.com/to/iivPVl",
    es: "https://busbudsurveys.typeform.com/to/vhfT11",
    "es-419": "https://busbudsurveys.typeform.com/to/vhfT11",
    "es-mx": "https://busbudsurveys.typeform.com/to/vhfT11",
    fr: "https://busbudsurveys.typeform.com/to/SOwOHg",
    "fr-ca": "https://busbudsurveys.typeform.com/to/SOwOHg",
    it: "https://busbudsurveys.typeform.com/to/MJzxTh",
    nl: "https://busbudsurveys.typeform.com/to/kHrFq2",
    pl: "https://busbudsurveys.typeform.com/to/uCXpDI",
    pt: "https://busbudsurveys.typeform.com/to/gi7xDR",
    "pt-pt": "https://busbudsurveys.typeform.com/to/gi7xDR",
    ru: "", // FIXME
    sv: "https://busbudsurveys.typeform.com/to/UtazGL",
    tr: "https://busbudsurveys.typeform.com/to/wQsUf9",
    zh: "https://busbudsurveys.typeform.com/to/TLLEO1"
  }
};
export const terms_link_template =
  "/{locale}/terms/{ticket_type}/{departure_id}";
export const third_party_link_template = {
  booking_com_search:
    "https://sp.booking.com/searchresults.html?aid={aid}&checkin={checkin_date}&checkout={checkout_date}&dest_id={dest_id}&dest_type=city&lang={lang}&selected_currency={currency}&label=device_id%3D{device_id}",
  booking_com_demand_api: "https://demandapi.booking.com/3.1",
  expedia:
    "https://prf.hn/click/camref:1100l3GTYj/destination:https://www.expedia.com/Hotel-Search?startDate={checkin_date}&endDate={checkout_date}&adults={adults}&affcid={affcid}"
};
export const google_maps_link_template =
  "https://www.google.com/maps/search/?api=1&query={lat},{lon}";
