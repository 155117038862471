import type { CalendarContextState } from "@app/components/calendar/CalendarContext";
import IntlProviderService from "@app/services/intl-provider";
import type {
  LiteTranslatorService,
  TranslatorService
} from "@app/services/translator";
import {
  formatToUtcDate,
  getEndOfLastWeekOfTheMonth,
  getStartOfFirstWeekOfTheMonth
} from "@app/utils/dates-without-moment";
import { IdleValue } from "@app/utils/idle-value";

import { isSupportedLocale, Locales } from "../../../../config/locales";

type IsoDate = string;
type Translations = Record<
  "nextMonth" | "previousMonth" | "selectedDay",
  string
>;

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export default class CalendarAdapter implements CalendarContextState {
  protected locale: string;
  public translations: Translations = {
    nextMonth: "",
    previousMonth: "",
    selectedDay: ""
  };
  private weekdays: string[];
  private first_day_of_week: number;
  private month_year_formatter: IdleValue<(date: IsoDate) => string | null>;

  public constructor(
    locale: string,
    protected translator: TranslatorService | LiteTranslatorService,
    protected now: IsoDate // YYYY-MM-DD in the current timezone
  ) {
    if (!isSupportedLocale(locale) || !Locales.supported_locales[locale]) {
      throw new Error(`Locale not found: ${locale}`);
    }
    this.locale = Locales.supported_locales[locale].full_locale;
    this.weekdays = [...Locales.supported_locales[locale].weekDays];
    this.first_day_of_week = Locales.supported_locales[locale].firstDayOfWeek;

    this.setTranslations();

    this.month_year_formatter = new IdleValue(() => {
      // The currency is required in IntlProviderService to be initialized but has no effect
      const intlService = new IntlProviderService({
        locale: this.locale,
        currency: "USD"
      });
      const format_options = {
        month: "long",
        year: "numeric"
      } as const;
      intlService.preloadDatetimeFormat(format_options);

      return (date: IsoDate) => intlService.formatDate(date, format_options);
    });
  }

  public getTranslations(): Translations {
    return this.translations;
  }

  private setTranslations() {
    if (!this.translator) {
      return;
    }

    this.translations = {
      nextMonth: this.translator.t("!calendar.nextMonth"),
      previousMonth: this.translator.t("!calendar.previousMonth"),
      selectedDay: this.translator.t("!calendar.selectedDay")
    };
  }

  public getWeekdays(): string[] {
    return this.weekdays;
  }

  public getMonthYear(date: IsoDate): IsoDate {
    const month_year_formatter = this.month_year_formatter.getValue();
    const monthYear = month_year_formatter(date);

    if (monthYear === null) {
      throw new Error(`Invalid date: ${date}`);
    }

    return monthYear;
  }

  public getWeekArray(date: IsoDate): IsoDate[][] {
    const start = getStartOfFirstWeekOfTheMonth(date, this.first_day_of_week);
    const end = getEndOfLastWeekOfTheMonth(date, this.first_day_of_week);

    let count = 0;
    let current = start;
    const nestedWeeks: IsoDate[][] = [];
    while (current.getTime() <= end.getTime()) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(formatToUtcDate(current));

      current = new Date(current.getTime() + ONE_DAY_IN_MS);

      count += 1;
    }

    return nestedWeeks;
  }

  public getDateOrClosestDateInFuture(date: IsoDate): IsoDate {
    const saved_date = new Date(date);

    if (saved_date.toISOString().slice(0, 7) >= this.now.slice(0, 7)) {
      return date;
    }

    return this.now;
  }

  public getToday(): IsoDate {
    return this.now;
  }

  public addDays(date: IsoDate, nbDaysToAdd: number): IsoDate {
    return formatToUtcDate(
      new Date(new Date(date).getTime() + nbDaysToAdd * ONE_DAY_IN_MS)
    );
  }
}
