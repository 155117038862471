import React from "react";

import { DesktopSelectorContainer } from "@app/components/adaptive-selector-container/desktop";
import { MobileSelectorContainer } from "@app/components/adaptive-selector-container/mobile";
import type { AdaptiveSelectorProps } from "@app/components/adaptive-selector-container/types";
import { useLiteAppContext } from "@app/helpers/hooks";

/**
 * Displays the children inside a full-screen dialog on mobile or inside a pop-over dropdown on desktop.
 *
 * @param root0
 * @param root0.children
 * @param root0.dialog_title
 * @param root0.dialog_actions
 * @param root0.onClose
 * @param root0.anchor_element
 * @param root0.popper_props
 * @param root0.dialog_props
 * @param root0.isOpen
 */
export const AdaptiveSelectorContainer: React.FC<AdaptiveSelectorProps> = ({
  children,
  ...props
}) => {
  const { device } = useLiteAppContext();

  if (!props.isOpen) {
    return null;
  }

  if (device?.is_mobile) {
    return (
      <MobileSelectorContainer {...props}>{children}</MobileSelectorContainer>
    );
  }

  return (
    <DesktopSelectorContainer {...props}>{children}</DesktopSelectorContainer>
  );
};
