// Never use moment in there, as we don't want to ship moment by default with the Calendar

import type { IsoDate } from "@app/components/calendar/Calendar.types";

export function isSameMonth(date: IsoDate, comparing: IsoDate): boolean {
  return date.substr(0, 7) === comparing.substr(0, 7);
}

export function getDayOfWeek(date: IsoDate): number {
  return new Date(date).getUTCDay();
}

export function getDayOfMonth(date: IsoDate): number {
  return parseInt(date.slice(8, 10));
}

export function isSameDay(date: IsoDate, comparing: IsoDate): boolean {
  return date === comparing;
}

export function isBeforeDay(date: IsoDate, comparing: IsoDate): boolean {
  return date < comparing;
}
